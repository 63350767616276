 
* {
    font-family: "Open Sans", sans-serif;
}

a {
    color: inherit;
}

    a:hover {
        color: inherit;
    }


.bg-whisper {
    background-color: #eee;
}

.bg-skyblue {
    background-color: #89b2cd;
}

.bg-darkblue {
    background-color: #00415e;
}

.bg-black {
    background-color: black;
} 
.bg-prussian-blue {
    background: #00415e !important;
}

/* Typography */  
.thankyou-access {

    font-size: 22px;
}

h1 {

}

h2 {

    font-weight: bold;
}

h3 {

    font-weight: 600;
}
 
h4 {
    font-size:16px;

}
p {
    font-size: 16px;
}

h5 {
    font-size: 16px; 
    font-weight: 600;
}

label{
    font-size: 16px; 
}
 
/* Text */
.text-gray{
    color: gray;
}
.text-red{
    color:red; 
}

.text-cerulean {
    color: #00649b;
}

.text-skyblue {
    color: #89b2cd;
}

.text-prussian-blue {
    color: #00415e;
}
  
.btn-light {
    background-color: #eee;
    color: #00415e;
}

.btn-skyblue {
    background-color: #89b2cd;
    color: #fff;
}

.btn-prussian-blue {
    background-color: #00415e;
}

.btn-matterhorn {
    background-color: #535353;
}

.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 13px;
    position: static;
    width: 69px;
    height: 38px;
    left: 0px;
    top: 0px; 
    box-sizing: border-box;
    flex: none;
    border-radius: 0%;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
}
  
.btn-ghost {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 13px;
    position: static;
    width: 69px;
    height: 38px;
    left: 0px;
    top: 0px;
    background: #e4e7eb;
    border: 1px solid #e4e7eb;
    box-sizing: border-box;
    flex: none;
    border-radius: 0%;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
}

.btn-cerulean {
    background-color: #00649b;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 13px;
    position: static;
    width: 71px;
    height: 38px;
    left: 0px;
    top: 0px;
    border: 1px solid #00649b;
    box-sizing: border-box;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 0%;
    margin: 0px 0px;
}
  
/* Custom Checkboxes*/ 
.nw-checkbox{
    padding: 13px;  
    margin-right:4em;  
    margin-bottom: 3em;
    background-color: #F0F0F0;
    width: 13.5em;
    height: 3em;
    white-space: nowrap;
    border-radius:35px; 
    display:inline-block;
    
}
.nw-checkbox .form-check{
    margin: 0px 5px;
}
.nw-checkbox .form-check-label{
  color: black;
  font-size:14px;
} 

.fw-600 {
    font-weight: 600; 
}